import { withPrefix } from 'gatsby';

import { css } from '@emotion/react';
import { charcoal, glass, gray5, gray7, gray8, white } from '@fire/_color';
import { buttonMedium, filterBarBoxShadow } from '@fire/_const';
import { bold, fontFace, fontSizeBody, fontSizeMedium } from '@fire/_fonts';
import { media, mediaMax } from '@fire/_mediaQueries';

export const filterContainerWrapper = css`
  position: relative;
  z-index: 11;
`;

export const filterContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 70px;
  padding: 0 24px;
  position: relative;
  width: 100%;

  ${media.md} {
    padding: 14px 32px;
  }
`;

export const filterContainerDesktop = css`
  label: filter-container-desktop;
  ${filterContainer};

  .onTop & {
    background: ${glass};
  }
`;

export const filterContainerButtonPanel = css`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 42px;
`;

export const filterContainerMobile = css`
  label: filter-container-mobile;
  ${filterContainer};
  display: flex;
  justify-content: space-between;
  padding-bottom: 0;
  gap: 10px;
  ${media.md} {
    flex-direction: row;
  }
`;

export const baseFilterButton = css`
  ${buttonMedium}
  ${fontFace};
  align-items: center;
  background-color: white;
  border-color: ${gray8};
  border-radius: 8px;
  border-style: solid;
  border-width: 2px;
  font-size: ${fontSizeBody};
  color: ${charcoal};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  transition: background 0.25s ease;

  ${mediaMax.xxs} {
    padding: 6px 10px;
  }

  &:hover {
    background: ${gray5};
    transition: background 0.25s ease;
  }
`;

export const icon = css`
  margin-right: 6px;
`;

export const filterButton = css`
  ${baseFilterButton}
  margin: 0 6px 0 0;

  ${mediaMax.lg} {
    justify-content: space-between;
  }

  span {
    white-space: nowrap;
  }

  span:last-of-type:not(:empty) {
    border-left: 1px solid ${gray8};
    line-height: 1;
    margin-left: 8px;
    padding-left: 8px;
    margin-top: 1px;
  }
`;

export const guestpickerText = hasDates => css`
  ${mediaMax.xxs} {
    display: {
      ${hasDates ? 'none' : 'flex'}
    }
  }
`;

export const filterIcon = css`
  height: 16px;
  width: 16px;
`;

export const filterButtonText = css`
  margin-right: 8px;
  white-space: nowrap;
`;

export const mapButton = css`
  ${baseFilterButton}
  justify-content: flex-start;

  img {
    height: 20px;
    margin-bottom: 1px;
    margin-right: 8px;
    width: 20px;
  }

  ${media.lg} {
    display: none;
  }

  ${mediaMax.lg} {
    padding: 6px;
  }
`;

export const filterModal = css`
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1001;
`;

export const filterModalOverlay = css`
  background-color: rgba(255, 255, 255, 0.7);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
`;

export const filterModalWidth = css`
  width: 60%;

  ${mediaMax.lg} {
    width: 100%;
  }
`;

export const filterModalContent = css`
  ${filterModalWidth}
  background: ${white};
  border-right: 1px solid ${gray5};
  height: 100%;
  overflow-y: auto;
  padding: 28px;
  position: relative;

  &:after {
    content: '';
    display: block;
    padding-bottom: 90px;
  }
`;

export const filterModalFooter = css`
  ${filterModalWidth}
  align-items: center;
  background: ${gray5};
  border-right: 1px solid ${gray5};
  bottom: 0;
  display: flex;
  font-size: ${fontSizeBody};
  height: 90px;
  justify-content: space-between;
  left: 0;
  padding: 0 28px;
  position: fixed;
  ${filterBarBoxShadow};

  > span {
    color: ${charcoal};
    cursor: pointer;
    font-weight: ${bold};
    text-decoration: underline;
  }
`;

export const filterTitle = css`
  color: ${charcoal};
  font-size: 28px;
  font-weight: ${bold};

  margin-bottom: 24px;
`;

export const gridWrap = css`
  column-count: 2;
  column-gap: 48px;

  ${mediaMax.xs} {
    column-count: 1;
  }
`;

export const categoryTitle = css`
  color: ${charcoal};
  font-size: 20px;
  font-weight: ${bold};

  margin-bottom: 12px;
`;

export const filterCategory = css`
  break-inside: avoid;
  clear: both;
  column-break-inside: avoid;
  display: inline-block;
  float: left;
  margin-bottom: 40px;
  page-break-inside: avoid;
  vertical-align: top;

  li {
    min-height: 24px;
  }
`;

export const checkBoxItem = css`
  display: block;
`;

export const ratingLabelPrefix = css`
  display: inline-block;
  padding-right: 5px;
`;

export const ratingExtraLabel = css`
  font-size: ${fontSizeMedium};
  margin-left: 5px;

  img {
    height: 16px;
    margin-right: 3px;
    max-width: 22px;
    vertical-align: middle;
    width: 100%;
  }
`;

export const mobileFilters = css`
  display: none;

  ${mediaMax.md} {
    display: inherit;
  }
`;

export const desktopFilters = css`
  ${mediaMax.md} {
    display: none;
  }
`;

export const textActingLikeAButton = css`
  cursor: pointer;
`;

const hideOnMobile = css`
  ${mediaMax.md} {
    display: none;
  }
`;

const hideOnDesktop = css`
  ${media.md} {
    display: none;
  }
`;

export const activeFiltersPanel = css`
  ${hideOnMobile}
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
export const activeFiltersPanelMobile = css`
  ${hideOnDesktop}
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0px 25px;
`;

export const activeFilter = css`
  align-items: center;
  background-color: ${gray5};
  box-sizing: border-box;
  color: ${charcoal};
  display: flex;
  flex-direction: row;
  font-size: ${fontSizeMedium};
  margin: 10px 10px 0 0;
  overflow: hidden;
  padding: 4px 10px 4px 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 8px;
`;

export const deleteActiveFilterBtn = css`
  cursor: pointer;
  display: inline-block;
  fill: currentcolor;
  height: 13px;

  margin-left: 6px;
  stroke-width: 0;
  stroke: currentcolor;
  width: 13px;
  line-height: 1;

  img {
    height: 12px;
    width: 10px;
  }
`;

export const clearFiltersLink = css`
  font-size: ${fontSizeMedium};
  margin: 3px 10px 0 0;
  padding-top: 5px;
`;

export const resetButton = css`
  ${fontFace};
  background: transparent;
  border: none;
  color: ${charcoal};
  cursor: pointer;
  font-size: ${fontSizeBody};
  padding: 0;
  text-decoration: underline;

  &:active {
    opacity: 0.6;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:focus {
    outline: 0;
  }

  actions & {
    text-align: left;
  }
`;

export const nights = css`
  align-self: flex-end;
  font-size: ${fontSizeMedium};
  margin-bottom: 6px;
  text-align: center;
  width: 60%;

  ${media.xl} {
    align-self: center;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    margin-right: 24px;
    text-align: left;
    width: 50%;
  }
`;

export const applyButtonWrapper = css`
  width: 60%;

  ${media.xl} {
    width: auto;
  }
`;

export const cancel = css`
  ${resetButton}
  cursor: pointer;
`;

export const actions = css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${media.xl} {
    width: 50%;
  }
`;

export const guestContainer = css`
  background: ${white};
  padding: 0px;
  width: 320px;
  z-index: 1;
`;

export const pickerRow = css`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;

  p {
    font-weight: ${bold};
  }
`;

export const kidsAgesTitle = css`
  padding: 16px 0px;
`;

export const ageFields = css`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  ${media.xl} {
    grid-template-columns: 48% 48%;
    justify-content: space-between;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;

    &::-ms-expand {
      display: none;
    }
  }
`;

export const actionBox = css`
  display: none;

  ${media.xl} {
    display: flex;
    margin: 32px 0 0;

    button {
      cursor: pointer;
    }
  }
`;

export const input = css`
  -moz-appearance: none;
  -webkit-appearance: none;
  ${fontFace};
  background-color: ${white};
  border-radius: 0;
  border: 1px solid ${gray7};
  display: flex;
  flex-direction: row;
  font-size: ${fontSizeBody};
  font-weight: ${bold};
  height: 40px;
  width: 100%;

  select {
    font-family: ${fontFace};
    font-size: ${fontSizeBody};
    font-weight: ${bold};
    padding-left: 16px;
    width: 100%;
  }
`;

export const fakeInput = css`
  background-image: url(${withPrefix('icons/arrowDark.svg')});
  background-position: right 10px top 16px;
  background-repeat: no-repeat;
  border: none;
  width: 100%;
`;

export const datesHeader = css`
  display: flex;
  max-width: 650px;
  padding: 0 24px;
`;

export const dates = css`
  display: flex;
  flex-flow: column;
  margin-bottom: 24px;
  width: 50%;

  span {
    :last-of-type {
      font-weight: ${bold};
    }
  }
`;

export const info = css`
  display: flex;
  justify-content: space-between;
  padding: 0 24px 24px;
  width: 618px;
`;

export const title = css`
  font-size: 26px;
  font-weight: ${bold};
  padding: 0 24px 24px;
`;

export const subtitle = css`
  font-size: ${fontSizeMedium};
`;

export const guests = css`
  padding: 0 24px;
`;

export const resetFormButton = css`
  margin-left: 16px;
`;

export const counter = css`
  align-self: flex-end;
  flex-basis: 100%;
  text-align: right;
`;
